<template>
    <div class="w-full h-full flex flex-col items-center md:justify-center bg-beige overflow-auto">
        <div 
            v-if="!portal"
            class="flex flex-col md:flex-row m-8 md:rounded-lg md:overflow-hidden">
            
            <div class="w-full md:w-64 bg-gray-darker text-white p-8 pl-12 flex flex-col rounded-t-lg md:rounded-none">
                <div class="md:h-32">
                    <img 
                        src="/assets/logo.svg" 
                        class="my-4"
                    />
                </div>
                
                <h2>
                    Fellesbruker innlogging
                </h2>
                <p class="text-gray-lightest">
                    Skriv inn tilgangskode for fellebrukeren. Har du glemt den, trenger ny, eller fått en tilgangskode på avveie må du kontakte administrator.
                </p>
            </div>
            
            <div class="w-full md:w-auto flex-grow bg-white p-8 md:px-12 h-full flex items-center rounded-b-lg md:rounded-none ">
                
                <div class="w-full">
                    <form 
                        @submit.prevent="onSignIn()"
                        class="w-full">
                        
                        <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                            {{error.text || error}}
                        </div>
                        
                        <InputSingleline 
                            v-model="accessCode"
                            label="Tilgangskode"
                            name="accessCode"
                            placeholder="Tall og/eller bokstaver"
                            type="text"
                            :required="true"
                            :autofocus="true"
                        />
                        
                        <button 
                            type="submit"
                            @click.prevent="onSignIn()" 
                            :disabled="loading == 'checking'"
                            class="button submit my-4 w-full" 
                            :class="{'loading' : loading == 'checking'}">
                            
                            Logg på
                        </button>
                    </form>
                    
                    <div class="text-center flex flex-col">
                        <router-link :to="{ name: 'signin'}">
                            Tilbake til normal innlogging
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PortalMixins from '@/mixins/PortalMixins';

    export default {
        mixins: [
            PortalMixins,
        ],
        
        data() {
            return {
                error: null,
                // loading: false,
                accessCode: null, // 'e42be504',
                accessToken: null,
                portal: null,
            }
        },
        
        methods: {
            async onSignIn(){
                await this.signIn();
                
                console.log('done! ');
                console.log('maybe redirect to portal-home...');
                console.log('this.portal', this.portal);
                
                this.$router.push({ name: 'portal-home', params: {accountId: this.portal.accountId} });
            },
            
            onSignOut(){
                this.signOut();
            },
        },
        
        async mounted() {
            if (localStorage.accessToken) {
                this.accessToken = localStorage.accessToken;
                this.portal = JSON.parse( localStorage.portal );
                console.log('should redirect to portal-home...');
                
                this.$router.push({ name: 'portal-home', params: {accountId: this.portal.accountId} });
            }
        },
    }
</script>